.forgot-password-container  {
  width: 600px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
  padding: 50px;

  h2 {
      margin: 10px 0;
  }

  .buttons-container {
      display: flex;
      justify-content: space-between;
  }
}

@media only screen and (max-width: 992px) {
    .forgot-password-container {
        width: 70%;
    }
  }

  @media only screen and (max-width: 768px) {
    .forgot-password-container {
      width: 90%;
    }
  }