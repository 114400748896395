.qr-code {
  .tracking-container {
    flex-direction: column;
  }
  .selectable-table-container {
    margin: 5rem 0 0;
    table {
        width: 100%;
        border-collapse: collapse;
        margin: 0 0 50px;

        thead {
            tr {
                th {
                    padding: 15px 20px;
                    text-align: left;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 15px 20px;
                }
            }
        }
    }

    .remove {
      cursor: pointer;
      padding: 5px;
    }

    .buttons-container {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 0 20px;
    }
}

    #qr-code {
        &:focus {
            outline: none;
        }
    }
    
    .right {
        float: right;
        line-height: 24px;
        font-weight: 600;
        margin: 10px 0;
        padding: 7.5px 0;

        .switch {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;
            margin: 0 0 0 10px;
          }
          
          .switch input { 
            opacity: 0;
            width: 0;
            height: 0;
          }
          
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          .slider:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          input:checked + .slider {
            background-color: #2196F3;
          }
          
          input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
          }
          
          input:checked + .slider:before {
            -webkit-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px);
          }
          
          /* Rounded sliders */
          .slider.round {
            border-radius: 24px;
          }
          
          .slider.round:before {
            border-radius: 50%;
          }
    }
}