.authorize-container  {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
    padding: 50px;
  
    h2 {
        margin: 10px 0;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .authorize-container {
        width: 70%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .authorize-container {
        width: 90%;
    }
  }