.button-container {
    min-width: 165px;
    width: auto;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 20px 0 20px;
    font-size: 15px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    white-space: nowrap;
  
    &:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
  
    &.primary {
      border: 1px solid #ffb200;
      color: white;
      background-color: #ffb200;
  
      &:hover {
      background-color: white;
      color: #ffb200;
      }
    }
  
    &.inverted {
      background-color: white;
      color: black;
      border: 1px solid black;
  
      &:hover {
        background-color: black;
        color: white;
        border: 1px solid transparent;
      }
    }

    &.linked {
      padding: 0 0;
      min-width: initial;

      a {
        min-width: 165px;
        padding: 0 35px 0 35px;
        color: white;
      }
      

      &:hover a {
        color: black;
      }

      &.inverted {
        a {
          color: black;
        }
        
  
        &:hover a {
          color: white;
        }
      }
    }
  }
  