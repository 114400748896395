.dashboard {
    .button-container {
        min-width: initial;
    }

    .particulars {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
    }
}